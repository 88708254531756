<template>
    <div>
        <button @click="sendDataMqtt(data)">send data MQTT</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data : {
                topic : "Id_matrice2",
                message : "test message mqtt"
            }
        }
    },
    methods:{
        sendDataMqtt(data){
            const url = this.$api.getRESTApiUri() + `/send-data-mqtt`
            console.log(data.message)
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    topic: data.topic,
                    message : data.message,
                }),
            })
            .then((result) => {
                return result.json();
            })
            .then((data) => {
                console.log(data);
            })

        }
    }
}

</script>